var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
// ----------------------------------------------------------------------
export default function DashboardLayout() {
    var themeLayout = useSettingsContext().themeLayout;
    var isDesktop = useResponsive('up', 'lg');
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var isNavHorizontal = themeLayout === 'horizontal';
    var isNavMini = themeLayout === 'mini';
    var handleOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var renderNavVertical = _jsx(NavVertical, { openNav: open, onCloseNav: handleClose });
    if (isNavHorizontal) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), isDesktop ? _jsx(NavHorizontal, {}) : renderNavVertical, _jsx(Main, { children: _jsx(Outlet, {}) })] }));
    }
    if (isNavMini) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, __assign({ sx: {
                        display: { lg: 'flex' },
                        minHeight: { lg: 1 },
                    } }, { children: [isDesktop ? _jsx(NavMini, {}) : renderNavVertical, _jsx(Main, { children: _jsx(Outlet, {}) })] }))] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { onOpenNav: handleOpen }), _jsxs(Box, __assign({ sx: {
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                } }, { children: [renderNavVertical, _jsx(Main, { children: _jsx(Outlet, {}) })] }))] }));
}
